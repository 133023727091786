import { useLocation } from '@remix-run/react';
import { load, trackPageview } from 'fathom-client';
import { useEffect } from 'react';

export default function Fathom() {
  const location = useLocation();

  useEffect(() => {
    load('EFMSIQBA', {
      includedDomains: ['beta.exposit.ai'],
    });
  }, []);

  useEffect(() => {
    trackPageview();
  }, [location.pathname, location.search]);

  return null;
}
